


/* .column >*{ 
  flex-basis: 100%;
} */
/* .noHover{
  pointer-events: none;
} */

html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

body {
  background-color: white;
  color: black;

  font-family: Buenos Aires Book, sans-serif;
  margin: 0;
  padding: 0;
}

a {
  color: black;
  text-decoration: none;
}

@media only screen and (min-width: 768px) {
  body {
    font-size: 16px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 768px) {
  body {
    font-size: 15px;
  }
}
@media only screen and (min-width: 479px) {
  body {
    font-size: 14px;
  }
}

/* .overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;border-style: value;
  background-color: rgba(0,0,0,1);
  z-index: 2;
} */
.modalContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 3;
}
.modalVimeo {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 200px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  background-color: white;
  z-index: 3;
}

.closeBtn {
  /* position: sticky;
  top: 20px;
  right: 20px;
  margin-top: 100px; */
  z-index: 1;
}
.modalRight {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 10px;
  margin-left: 150px;
  z-index: 7;
}
.closeBtn {
  position: absolute;
  top: 20px;
  right: 20px;
  margin-top: 100px;
  z-index: 1;
}
/* wide video css */
#wide {
  display: grid;
  grid-column: 1/5;
  grid-row: 5/ 5;
}


#wide2 {
  display: grid;
  grid-column: 1/5;
  grid-row: 3/3;
}

#wide3 {
  display: grid;
  grid-column: 1/5;
  grid-row: 1/1;
}
#wide4 {
  display: grid;
  grid-column: 1/5;
  grid-row: 6/7;
}
#wide5 {
  display: grid;
  grid-column: 1/5;
  grid-row: 9/9;
}
@media screen and (max-width: 768px) {
  #wide,
  #wide2,
  #wide3,
  #wide4,
  #wide5 {
  display: grid;   
  grid-column: auto;
  
  }
}


.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}
/* @media only screen and (max§  width: 768px) {
  #wide {
    display: grid;
    
  }
  #wide2 {
   
  }
  #wide3 {
   
  }
  #wide4 {
   
  }
} */
